<template>
  <div>
    <!--begin::BusinessAccount-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Business Account View</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="mt-3 mb-3 mr-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span
                  class="v-btn__content"
                  @click="redirectToEditBAT($route.params.id)"
                >
                  Edit
                </span>
              </button>
              <button
                type="button"
                class="mt-3 mb-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span
                  class="v-btn__content"
                  @click="redirectToBusinessAccountTypeList()"
                >
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form
          class="form"
          novalidate="novalidate"
          id="kt_business_account_form"
        >
          <!--begin::Example-->
          <div class="example mb-10">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <b>Company Name:</b> {{ business_account_type.company.name }}
                </v-col>                
              </v-row>

              <v-row no-gutters class="mt-3">
                <v-col cols="12" sm="6">
                  <b>Business Account Type: </b> {{ business_account_type.name }}
                </v-col>               
              </v-row>              
            </v-form>
          </div>
        </form>
      </div>
    </div>
    <!--end::BusinessAccount-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

export default {
  name: "businessAccountCreate",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      business_account_type: {
        name: "",
        company_id: "",
        company_type: ""
      },
      company_users: "",
      company_list: [],
      business_account_types_list: [],
      valid: true,
      disable_btn: false,
      edit_form: false,
      show1: true
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["companyUsers"]),
    computedDateFormattedMomentjs() {
      return this.business_account.renewal_date
        ? moment(this.business_account.renewal_date).format("MM-DD-YYYY")
        : "";
    }
  },
  mounted() {
    let context = this;
    if (this.$route.params.id) {
      context.edit_form = true;
      axios({
        method: "get",
        url: "business-account-types/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          console.log('RESULT')
          console.log(result)
          if (result.data.business_account_type) {
            context.business_account_type = result.data.business_account_type;            
          } else {
            context.$router.go(-1); //go back to list
            context.disable_btn = false;
            Swal.fire(result.data.error, "", "error");
          }
        },
        function() {
          context.$router.go(-1); //go back to list
          context.disable_btn = false;
          Swal.fire("Error", "Business Account type not found!", "error");
        }
      );
    }

    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Business Account Type" }]);

    const business_account_form = KTUtil.getById("kt_business_account_form");

    this.fv = formValidation(business_account_form, {});
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {    
    redirectToEditBAT(ba_id) {
      this.$router.push({
        name: "businessAccountType.edit",
        params: { id: ba_id }
      });
    },
    redirectToBusinessAccountTypeList() {
      let context = this;
      context.$router.go(-1);
    }
  }
};
</script>
